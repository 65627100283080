import React, { useContext, useEffect } from "react";
import AppContext from "@src/context";
import Seo from "@components/utility/SEO";
import { KfHeroUtility, KfAnchorLink } from "@klickinc/kf-react-components";

const MakingTheGrade = () => {
	const ctx = useContext(AppContext);

	useEffect(() => {
		ctx.setPageClass("page--transcript page--transcript-making-the-grade");
	}, []);

	return (
		<>
			<section className="transcript-section">
				<div className="container container--inner">
					<KfHeroUtility addedClass="heading-hero-blue heading">
						Transcript
					</KfHeroUtility>
					<p className="uppercase mb-4">Sima Perez is the mom of a patient and her testimonial reflects real life experiences. She was compensated for the use of her experiences.</p>
					<h2 className='orange-heading'>Making the grade</h2>
					<div className="script">
						<div className="script--body">
							<p><span className="name">Sima:</span> <span className="copy">I am preparing Christopher for future transitions very similarly to the way I helped him from elementary school into high school. We decided, both my husband and I, we decided to enroll him at our, the high school that will be his home school here in Long Beach. And we have to get, as parents, even though I work at a school, Christopher did not have an IEP [individualized education program]. So I had to start the process of the IEP. And with that, when they, the public school, we have to talk to them about the needs and what is it that would help Christopher thrive and be a great student at school. And so, I met with the school district.  We came up with a great a plan for Christopher. And can I just say that he loves it. And that makes me so happy knowing that he's doing really well in school.</span></p>
							<p><span className="name">Sima:</span> <span className="copy">And so as parents, I think that we just need to be right next to them and supporting them and guiding them and just fighting for them, actually fighting for them, making sure that they get the bus if parents can't transport them to school, making sure that they have the right equipment whether it be technology or assistive technology, whatever that looks like for your child. And so, also, be familiar with the person, the Director of Special Ed at school. That, if I was to give anybody an advice, piece of advice is “know the Special Ed Director of the school” not the principal, but the Special Ed Director. Be their best friend.  Talk to them about your child. And let me tell you, everything just kind of falls into place because once you ask for certain things, your child will be that much better in school.</span></p>
							<p className="highlighted-copy mt-10 lowercase-highlighted-copy">To get connected with a Peer Navigator, please contact us at <KfAnchorLink url="mailto:peernavigator@ptcbio.com" linkClass="underline font-bold">peernavigator@ptcbio.com</KfAnchorLink> or <KfAnchorLink url="tel:8662825878" linkClass="underline font-bold">(866) 282-5878</KfAnchorLink>.</p>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default MakingTheGrade;

export function Head() {
	return (
		<Seo title="Video Transcript: Making the Grade - EMFLAZA® (deflazacort)" addSocialMeta={false}>
			<meta name="robots" content="noindex" />
		</Seo>
	);
};
