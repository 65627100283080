import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { useSiteMetadata } from '@hooks/use-site-metadata';

const SEO = ({ title, description, addSocialMeta, children }) => {
	const { pathname } = useLocation();
	const { defaultTitle, siteUrl, defaultImage } = useSiteMetadata();
	const canonicalURL = `${siteUrl}${pathname.replace(/\//, '')}`;

	const seo = {
		title: title || defaultTitle,
		description: description,
		image: defaultImage,
		url: `${siteUrl}${pathname}`,
		canonicalURL: canonicalURL,
	};
	return (
		<>
			<title>{seo.title}</title>
			<meta name="image" content={seo.image} />
			{addSocialMeta && (
				<>
					<meta property="og:title" content={seo.title} />
					<meta property="og:image" content={seo.image} />
					<meta property="og:type" content="website" />
					<meta property="og:url" content={seo.url} />

					<meta name="twitter:site" content="@EQRx_GLOBAL" />
					<meta name="twitter:title" content={seo.title} />
					<meta name="twitter:image" content={seo.image} />
					<meta name="twitter:card" content="summary_large_image" />
				</>
			)}
			{seo.description && (
				<>
					<meta name="description" content={seo.description} />
					{addSocialMeta && (
						<>
							<meta property="og:description" content={seo.description} />
							<meta name="twitter:description" content={seo.description} />
						</>
					)}
				</>
			)}
			<meta name="facebook-domain-verification" content="jwux0lp6a2zghksgctu9yh6sqzljnx" />
			<link rel="preconnect" href="https://www.google-analytics.com" />
			<link rel="dns-prefetch" href="https://www.google-analytics.com" />
			<link rel="preconnect" href="https://www.googletagmanager.com" />
			<link rel="dns-prefetch" href="https://www.googletagmanager.com" />
			<link rel="preload" href="/fonts/fonts.css" as="style" />
			<link rel="canonical" href={seo.canonicalURL} />
			{children}
		</>
	);
};

export default SEO;

SEO.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string,
	addSocialMeta: PropTypes.bool,
	children: PropTypes.any,
};

SEO.defaultProps = {
	description: null,
	addSocialMeta: true,
	children: null,
};
