import { graphql, useStaticQuery } from 'gatsby';

export const useSiteMetadata = () => {
    const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					defaultTitle: title
					siteUrl: url
					defaultImage: image
				}
			}
		}
    `);

    return data.site.siteMetadata;
};
